// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-factorio-js": () => import("./../../../src/pages/factorio.js" /* webpackChunkName: "component---src-pages-factorio-js" */),
  "component---src-pages-format-js": () => import("./../../../src/pages/format.js" /* webpackChunkName: "component---src-pages-format-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tax-js": () => import("./../../../src/pages/tax.js" /* webpackChunkName: "component---src-pages-tax-js" */)
}

